// Import the functions you need from the SDKs you need
const { initializeApp } = require("firebase/app")
const { getFirestore } = require( "firebase/firestore")
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app"s Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDMfw6QARB3UhNvYQJg5rfr48jvsF1B9f8",
  authDomain: "seedbox-os-mvp.firebaseapp.com",
  databaseURL: "https://seedbox-os-mvp-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "seedbox-os-mvp",
  storageBucket: "seedbox-os-mvp.appspot.com",
  messagingSenderId: "1022936682866",
  appId: "1:1022936682866:web:f53b88d6a8cae78f7b8deb",
  measurementId: "G-WEFCR21DPK"
}

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig)
module.exports = firebaseApp
module.exports.db = getFirestore(firebaseApp)
