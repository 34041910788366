import iframeResizer from "iframe-resizer/js/iframeResizer"

const iframeResizerDirective = {
  beforeMount: (el, { value = {} }) => {
    el.addEventListener("load", () => {
      const resizer = iframeResizer({
        ...value
      }, el)
    })
  },
  beforeUnmount: (el) => {
    try {
      el.iFrameResizer.removeListeners()
    } catch(err) {
      console.log("iFrameResizer Error", err)
    }
  }
}

export default iframeResizerDirective