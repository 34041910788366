<template>
  <div class="logo">
    <img class="logo__image" :src="src">
  </div>
</template>

<script>
import { defineComponent, toRefs } from "vue"

export default defineComponent({
  props: {
    src: String,
  },
  setup(props) {
    // const { src } = toRefs(props)

    return {}
  },
})

</script>

<style lang="scss" scoped>
  .logo {
    position: relative;
    display: flex;
    justify-items: center;
    align-items: center;

    &__image {
      width: 100%;
    }
  }
</style>