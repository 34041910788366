<template>
  <div class="view-container view-notion-container">
    <Navbar></Navbar>
    <IframeContainer></IframeContainer>
  </div>
</template>

<script>
import { defineComponent } from "vue"
import Navbar from "@/components/Navbar"
import IframeContainer from "@/components/IframeContainer"

export default defineComponent({
  components: { IframeContainer, Navbar },
  setup() {

  },
})
</script>

<style lang="scss" scoped>
.iframe-container {
  width: 100vw;
  min-height: calc(100vh - 80px);
}
</style>