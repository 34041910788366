<template>
  <div class="iframe-container">
    <iframe
        v-ifresizer="{ bodyPadding: '0 0 80px', log: true }"
        class="iframe"
        width="100%"
        :src="src"
        id="notion-frame"
    >
    </iframe>
  </div>
</template>

<script>
import { defineComponent } from "vue"

export default defineComponent({
  props: ["src"],
  setup(props) {
  },
})
</script>

<style lang="scss" scoped>
.iframe-container {
  display: flex;
  position: relative;
}

.iframe {
  //flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0;
}
</style>
