import { watchSyncEffect } from "vue"
import { createRouter, createWebHistory } from "vue-router"
import { useSessionStore } from "@/stores/session"
import NotionContainer from "@/views/NotionContainer.vue"
import ProjectDashboard from "@/views/ProjectDashboard.vue"
import ProjectList from "@/views/ProjectList.vue"
import Login from "@/views/Login.vue"

let sessionStore

const routes = [
  {
    path: "/",
    beforeEnter: async (to, from, next) => {
      const project = await sessionStore.fetchCurrentProject()
      if (typeof project === "object") {
        const { id: projectId } = project
        next({ name: "Project", params: { projectId } })
      } else {
        next({ name: "Projects" })
      }
    },
    name: "Root",
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: ProjectList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/projects/:projectId",
    name: "Project",
    component: ProjectDashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/:phase/:deliverable/:step",
    name: "Deliverable",
    component: NotionContainer,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/:catchAll(.*)",
    redirect: "Root",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

function getSessionStore() {
  if (!sessionStore) {
    sessionStore = useSessionStore()
    sessionStore.$subscribe(async(mutation, state) => {

      const currentPath = router.currentRoute.value.path
      const isAuthenticated = !!state.user

      if (currentPath === "/login" && isAuthenticated) {
        setTimeout(
          () => {
            router.push("/")
          }, 5
        )

      } else if (currentPath !== "/login" && !isAuthenticated) {
        router.push("/login")
      }
    })
  }
  return sessionStore
}

// Authentication Guard
router.beforeEach(async (to, from, next) => {
  const sessionStore = getSessionStore()
  const isAuthenticated = await sessionStore.isAuthenticated()

  if(!isAuthenticated && to.matched.some(record => record.meta.requiresAuth)) {
    next({ name: "Login" })
  } else if(isAuthenticated && to.name === "Login") {
    next({ name: "Root" })
  } else {
    next()
  }
})

export default router
