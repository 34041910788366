import { createApp } from "vue"
import { createPinia } from "pinia"
import iframeResizerDirective from "@/directives/iframeResizer"
import App from "@/App.vue"
import router from "@/router"
import "./style/main.scss"
import FontAwesomeIcon from "@/services/icons"

const pinia = createPinia()

createApp(App)
  .use(router)
  .use(pinia)
  .directive("ifresizer", iframeResizerDirective)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app")
