import { computed, ref } from "vue"
import { defineStore } from "pinia"
import { collection, doc, getDocs, query, where } from "firebase/firestore"
import firebaseApp, { db } from "../services/firebase"
import {
  browserLocalPersistence,
  getAuth,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword
} from "firebase/auth"
import Project from "@/model/Project"

export const useSessionStore = defineStore("session", () => {
  const fbAuth = getAuth(firebaseApp)
  const pInitialized = setPersistence(fbAuth, browserLocalPersistence)

  const isLoading = ref(false)
  const currentProject = ref(void 0)
  const projects = ref(void 0)
  const user = ref(fbAuth.user)
  const userDataObj = ref(void 0)
  const errors = ref([])

  const projectsCollection = collection(db, "projects")

  const isAuthenticated = async () => {
    await pInitialized
    return !!user.value
  }

  async function updateUser(newUser) {
    user.value = newUser
    userDataObj.value = newUser ? doc(db, "users", newUser.uid) : void 0
  }

  function loginWithEmailAndPassword(email, password) {
    isLoading.value = true

    return signInWithEmailAndPassword(fbAuth, email, password).then((userCredential) => {
      updateUser(userCredential.user)
      return userCredential
    })
      .catch(error => {
        switch(error.code) {
        case "auth/user-not-found":
        case "auth/wrong-password":
          throw new InvalidCredentialsError()
        default:
          throw new GeneralAuthError()
        }
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  async function fetchCurrentProject() {
    // Return currentProject if it is defined
    if (currentProject.value) {
      return currentProject.value
    }

    // Return only project of user has only one
    const projects = await fetchProjects()
    if (projects && projects.length === 1) {
      currentProject.value = projects[0]
      return currentProject.value
    }

    // Return false, if currentProject is not defined and user has access to more than one projects
    return currentProject.value
  }

  async function fetchProjects(forceUpdate = false) {
    if (!projects.value || forceUpdate) {
      const projectsArray = []
      const q = query(projectsCollection, where("users", "array-contains",  userDataObj.value))
      const querySnapshot = await getDocs(q)
      querySnapshot.forEach((doc) => {
        projectsArray.push(new Project(doc))
      })
      projects.value = projectsArray
    }

    return projects.value
  }

  onAuthStateChanged(fbAuth, updateUser)

  return {
    fetchCurrentProject,
    fetchProjects,
    isAuthenticated,
    isLoading,
    user,
    loginWithEmailAndPassword,
    updateUser,
    errors
  }
})

export class GeneralAuthError extends Error {}
export class InvalidCredentialsError extends Error {}
