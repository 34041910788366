<template>
  <div class="view-container view-project-list">
    <Navbar></Navbar>
    <div class="project-list-container">
      <h2>Wähle ein Projekt</h2>
      <ul class="project-list">
        <li class="project-list__item" v-for="project in projects" :key="project.id">
          <router-link :to="project.url()">
            <font-awesome-icon :icon="`fa-solid fa-${project.data.icon}`"/>
            {{ project.data.name }}
          </router-link>
        </li>
      </ul>

    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import { useSessionStore } from "@/stores/session"
import Navbar from "@/components/Navbar"

export default defineComponent({
  components: { Navbar },
  setup() {
    const sessionStore = useSessionStore()
    const projects = ref([])

    sessionStore.fetchProjects()
      .then(pjs => projects.value = pjs)

    return {
      projects,
    }
  },
})
</script>

<style lang="scss" scoped>
@use "/app/style/util/responsiveness" as res;

h2 {
  margin-top: 4rem;
  @include res.from('tablet') {
    margin-left: auto;
    margin-right: auto;
  }

}

.project-list-container {
  display: flex;
  padding: 0 2rem;
  flex-direction: column;
  color: var(--color-white);
  overflow: auto;
  min-height: calc(100vh - 80px);
  background-color: var(--color-dark-gray);
}

ul.project-list {
  display: inline-flex;
  flex-direction: column;
  margin: 0px 2rem;
  list-style: none;
  padding: 0;

  @include res.from('tablet') {
    margin: 0 auto;
  }

  a {
    position: relative;
    font-size: 1.4rem;
    font-family: var(--primary-font);
    color: var(--color-white);
    transition: color, left .5s ease-out;
    left: 0;

    svg {
      position: absolute;
      top: .5rem;
      left: -2rem;
      font-size: 1rem;
      pointer-events: none;
      transition: left .5s ease-out;

      @include res.from('tablet') {
        top: .6rem;
        font-size: 1.5rem;
        left: -2.5rem;
      }
    }

    @include res.from('tablet') {
      font-size: 2rem;
    }
  }
}

li.project-list__item {
  display: inline-flex;
  position: relative;
  transition: text-indent .2s ease-out;
  text-indent: 0;
  padding: 1rem 2rem 1rem 0;
  margin: 0;
  max-width: 480px;

  &:hover {
    a {
      left: 2rem;
      color: var(--color-danger);

      svg {
        left: -4rem;

        @include res.from('tablet') {
          left: -4.5rem;
        }
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    border-bottom: 2px var(--color-white) solid;
    width: 240px;
  }
}

</style>
