<template>
  <nav class="navbar">
    <router-link to="/">
      <Logo src="/graphics/logo-icon.png"/>
    </router-link>
  </nav>
</template>

<script>
import { defineComponent } from "vue"
import Logo from "@/components/Logo"

export default defineComponent({
  components: { Logo },
  setup() {
  },
})
</script>

<style lang="scss" scoped>
.navbar {
  display: grid;
  height: 80px;
  background-color: var(--color-black);

  .logo {
    margin: 10px 20px;
    width: 60px;
  }
}
</style>