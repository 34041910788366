<template>
  <div class="view view-login">
    <div class="view-login__form-container">
      <Logo src="/graphics/logo.png"></Logo>
      <div class="view-login__message-container" v-if="messages && messages.length">
        <div v-for="(message, index) in messages" class="message" :key="index"
             :class="{ 'message-error': message.type === 'error'}">
          {{ message.text }}
        </div>
      </div>
      <form @submit.prevent="handleSubmit" class="view-login__form">
        <div class="form-field">
          <input class="" type="email" name="email" v-model="email" placeholder="E-Mail" required :disabled="isLoading">
          <span class="form-field__message"></span>
        </div>
        <div class="form-field">
          <input class="" type="password" name="password" v-model="password" placeholder="Passwort" required
                 :disabled="isLoading">
          <span class="form-field__message"></span>
        </div>
        <button type="submit" class="btn btn--red" :disabled="isLoading">Login</button>
        <LoadingAnimation v-if="isLoading"></LoadingAnimation>
      </form>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue"
import { storeToRefs } from "pinia"
import { useSessionStore, GeneralAuthError, InvalidCredentialsError } from "../stores/session"
import Logo from "../components/Logo"
import LoadingAnimation from "../components/LoadingAnimation"


export default defineComponent({
  components: { LoadingAnimation, Logo },
  setup() {
    const sessionStore = useSessionStore()
    const email = ref("")
    const password = ref("")
    const messages = ref([])
    const { isLoading } = storeToRefs(sessionStore)

    const handleSubmit = async () => {
      try {
        await sessionStore.loginWithEmailAndPassword(email.value, password.value)
        messages.value = []
      } catch (error) {
        if (error instanceof InvalidCredentialsError) {
          messages.value = [{
            type: "error",
            text: "Ungültige Anmeldedaten. Bitte versuchen Sie es erneut.",
          }]
        } else {
          messages.value = [
            {
              type: "error",
              text: "Unbekannter Loginfehler. Bitte versuchen Sie es später nochmal.",
            }]
        }
      }
    }

    return { email, password, isLoading, messages, handleSubmit }
  },
})
</script>

<style lang="scss" scoped>
@use '../style/util/responsiveness' as res;
@use '../style/components/form';

.view-login {
  display: flex;
  background-color: var(--color-dark-gray);
  align-items: center;
  justify-content: center;

  &__form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin: 0 auto;
    padding: 2rem 4rem;
    height: 100vh;
    width: 100%;
    color: var(--color-white);
    background-color: var(--color-black);
    box-sizing: border-box;


    @include res.from('tablet') {
      padding: 2rem 4rem;
      max-width: 480px;
      width: calc(100% - 8rem);
      height: auto;
      border-radius: 16px;
    }

    .logo {
      width: 100%;
      max-width: 380px;
      margin: -4rem auto 1rem;

      @include res.from('tablet') {
        margin: 2.5rem auto 1rem;
        width: 80%;
      }
    }
  }

  &__form {
    @extend .form;

    margin: 2rem 0;
  }

  .btn {
    margin: 3rem auto 2rem;

    @include res.from('tablet') {
      margin: 3.5rem auto 4rem;
    }
  }

  &__message-container {
    margin: 2rem 0 0;
    text-align: center;

    @include res.from("tablet") {
      padding: 0 2rem;
    }
  }

  .message-error {
    color: var(--color-danger);
  }

  .loading-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1rem;
  }
}

</style>