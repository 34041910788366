<template>
  <div class="view-container view-notion-container">
    <Navbar></Navbar>
        <IframeContainer :src="superUrl+'?v=0.0.12'"></IframeContainer>
<!--        <IframeContainer src="/iframe_debug.html"></IframeContainer>-->
<!--    <IframeContainer src="https://os.seedbox-ventures.com/iframe_debug"></IframeContainer>-->
  </div>
</template>

<script>
import { computed, defineComponent } from "vue"
import { SUPER_DOMAIN } from "@/services/constants"
import { useRoute } from "vue-router"
import { useSessionStore } from "@/stores/session"
import Navbar from "@/components/Navbar"
import IframeContainer from "@/components/IframeContainer"

export default defineComponent({
  components: { IframeContainer, Navbar },
  setup() {
    const route = useRoute()
    const sessionStore = useSessionStore()
    const { projectId } = route.params
    const project = computed(() => sessionStore.currentProject)
    const superUrl = computed(() => `https://${SUPER_DOMAIN}/projects/${projectId.toLowerCase()}`)

    return {
      project,
      superUrl,
    }
  },
})
</script>

<style lang="scss" scoped>
.iframe-container {
  min-height: calc(100vh - 80px);
}
</style>