export default class Base {
    id
    docRef
    data = {}

    constructor(docRef, defaultData = {}) {
      this.docRef = docRef
      if (docRef) {
        this.id = docRef.id
        this.data = { ...defaultData, ...docRef.data()  }
      }
    }

    toJSON() {
      return JSON.stringify({ id: this.id, ...this.data })
    }
}